import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {backoffice_caisse} from '../../shared/lefrueguanemploy';

export interface Frigo {
	id: number;
	restaurant_id: number;
	name: string;
	alert_max_temp: number;
	alert_min_temp: number;
	alert_email: string;
}

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	public apiUrl = environment.apiUrl + '/';
	private httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	private token: string = null;

	constructor(private http: HttpClient, private authService: NbAuthService, private router: Router) {
		this.authService.onTokenChange()
			.subscribe((token: NbAuthJWTToken) => {
				if (token.isValid() && token.getPayload().data) {
					this.token = token.getValue();
				} else {
					this.token = null;
				}
			});
	}

	handleError = (error: HttpErrorResponse) => {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			if (error.status === 401) {
				this.authService.logout('email');
				return;
			}
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${JSON.stringify(error.error)}`);
		}
		// Return an observable with a user-facing error message.
		return throwError(error);
	};

	getUsers(): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'user', this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getLastMonthReservations(restaurantId) {
		return this.http.get<any>(this.apiUrl + 'reservation?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getZeltyReservations(restaurantId, date) {
		return this.http.get<any>(this.apiUrl + 'reservation/zelty?restaurant_id=' + restaurantId + '&date=' + date,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	pushReservationToCaisse(id: any) {
		return this.http.post<any>(this.apiUrl + 'reservation/' + id + '/caisse',
			'',
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	reminderResa(id: any) {
		return this.http.post<any>(this.apiUrl + 'reservation/' + id + '/relance',
			'',
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	updateReservationNotationEmailSending(id: any, status: any) {
		return this.http.post<any>(this.apiUrl + 'reservation/' + id + '/notation_sending',
			{'after_mail_sendable': status},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	updateReservationStatus(id: any, status: any) {
		return this.http.post<any>(this.apiUrl + 'reservation/' + id + '/status',
			{'status': status},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	sendMessageToReservation(id: any, message: any) {
		return this.http.post<any>(this.apiUrl + 'reservation/' + id + '/message',
			{'message': message},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getRestaurants(): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'restaurants', this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getCommande(commande, restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'commande?id=' + commande + '&restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getCommandeTracabilite(commande, restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'commande_tracabilite?id=' + commande + '&restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getCommandeLocalTracabilite(commande, restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'commande_local/' + commande + '?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getCommandeTracabiliteImage(commande, image, restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'commande_tracabilite?id=' + commande + '&restaurant_id=' + restaurantId
			+ '&file=' + image, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getCommandeLocalTracabiliteImage(commande, image, restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'commande_local/' + commande + '?restaurant_id=' + restaurantId
			+ '&file=' + image, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	addCommandeTracabiliteImage(commande, image, restaurantId): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'commande_tracabilite?id=' + commande + '&restaurant_id=' + restaurantId,
			image, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	addCommandeLocalTracabiliteImage(commande, image, restaurantId): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'commande_local/' + commande + '?restaurant_id=' + restaurantId,
			image, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getFacturetteList(restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'facturette?restaurant_id=' + restaurantId, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public downloadFacturette(restaurantId, id): any {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		return this.http.get<any>(this.apiUrl + 'facturette?id=' + id + '&download&restaurant_id=' + restaurantId, options)
			.pipe(catchError(this.handleError));
	}

	public downloadDepotFile(restaurantId, id): any {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		return this.http.get<any>(this.apiUrl + 'caisse/' + id + '?download&restaurant_id=' + restaurantId, options)
			.pipe(catchError(this.handleError));
	}

	uploadFacturette(data, restaurantId): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'facturette?restaurant_id=' + restaurantId, data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	storeTips(data, restaurantId): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'tips?restaurant_id=' + restaurantId, data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getCommandeProduit(commande, restaurantId): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'commande_produit?id=' + commande + '&restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	updateCommandeProduit(commande, restaurantId, data): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'commande_produit?id=' + commande + '&restaurant_id=' + restaurantId,
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getCommandes(restaurantId: any) {
		return this.http.get<any>(this.apiUrl + 'commande?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getCommandesLocal(restaurantId: any) {
		return this.http.get<any>(this.apiUrl + 'commande_local?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getPendingCommandes(restaurantId: any) {
		return this.http.get<any>(this.apiUrl + 'commande?pending&restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getFournisseur(id: any) {
		return this.http.get<any>(this.apiUrl + 'fournisseur?id=' + id, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getFournisseurs() {
		return this.http.get<any>(this.apiUrl + 'fournisseur', this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getFrigos(restaurantId: number) {
		return this.http.get<Frigo[]>(this.apiUrl + 'frigos?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getLatestCaisse(restaurantId: number) {
		return this.http.get<backoffice_caisse>(this.apiUrl + 'caisse/latest?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getLatestComptages(restaurantId: number) {
		return this.http.get<any>(this.apiUrl + 'caisse/historique/comptage?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getBalance(restaurantId: number) {
		return this.http.get<any>(this.apiUrl + 'caisse/balance?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getLatestBanque(restaurantId: number) {
		return this.http.get<any>(this.apiUrl + 'caisse/historique/banque?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getFrigosReleves(restaurantId: number) {
		return this.http.get<any>(this.apiUrl + 'releve_frigo?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	storeFrigoTemperature(restaurantId: number, id: number, value: number, comment: number) {
		return this.http.post<any>(this.apiUrl + 'releve_frigo/' + id + '?restaurant_id=' + restaurantId,
			{'value': value, 'comment': comment},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	updateFrigo(restaurantId: number, id: any, data: any) {
		return this.http.put<any>(this.apiUrl + 'frigos/' + id + '?restaurant_id=' + restaurantId, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	createFrigo(restaurantId: number, data: any) {
		return this.http.post<any>(this.apiUrl + 'frigos?restaurant_id=' + restaurantId, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getPermissions() {
		return this.http.get<any>(this.apiUrl + 'user/permissions', this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getFournisseurRestaurant(restoId: number, id?: number) {
		return this.http.get<any>(this.apiUrl + 'fournisseur_restaurant?restaurant_id=' + restoId + (id ? '&id=' + id : ''),
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getNeededStock(fournisseurId: number, restaurant_id: number) {
		return this.http.get<any>(this.apiUrl + 'needed_stock?restaurant_id=' + restaurant_id + (fournisseurId ? ('&id=' + fournisseurId) : ''),
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	createNeededStock(data: any) {
		return this.http.post<any>(this.apiUrl + 'needed_stock', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateNeededStock(data): any {
		return this.http.put<any>(this.apiUrl + 'needed_stock/' + data.id, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getPrepareCommande(fournisseurId: number, restaurant_id: number) {
		return this.http.get<any>(this.apiUrl + 'prepare_commande?restaurant_id=' + restaurant_id + (fournisseurId ? ('&fournisseur_id=' + fournisseurId) : ''),
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getStock(fournisseurId: number, restaurant_id: number) {
		return this.http.get<any>(this.apiUrl + 'stock?restaurant_id=' + restaurant_id + (fournisseurId ? ('&id=' + fournisseurId) : ''),
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getProduits(fournisseurId: number, restaurant_id: number): any {
		return this.http.get<any>(this.apiUrl + 'produit' + (fournisseurId ? '?fournisseur_id=' + fournisseurId + (restaurant_id ? '&restaurant_id=' + restaurant_id : '') : ''), this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getUsersByRestaurant(restaurantId: number, full = false) {
		return this.http.get<any[]>(this.apiUrl + 'user_restaurant?restaurant_id=' + restaurantId + (full ? '&full=true' : ''),
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getRestaurantByUser(userId: number) {
		return this.http.get<any[]>(this.apiUrl + 'user_restaurant?user_id=' + userId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	getContracts(restaurantId?: number) {
		return this.http.get<any[]>(this.apiUrl + 'contracts' + (restaurantId ? ('?restaurant_id=' + restaurantId) : ''),
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public deleteContract(id: number): any {
		return this.http.delete<any>(this.apiUrl + 'contracts/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getPlanning(restaurantId: number, startDate, endDate): any {
		return this.http.get<any>(this.apiUrl + 'planning?restaurant_id=' + restaurantId + '&startDate=' + startDate + '&endDate=' + endDate,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getWaitingPlanning(restaurantId: number): any {
		return this.http.get<any>(this.apiUrl + 'planning/waiting?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getAvailablePlanning(): any {
		return this.http.get<any>(this.apiUrl + 'planning/available',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getMyPlanning(): any {
		return this.http.get<any>(this.apiUrl + 'planning/me',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	createCreneau(data: any) {
		return this.http.post<any>(this.apiUrl + 'planning', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateCreneau(data): any {
		return this.http.put<any>(this.apiUrl + 'planning/' + data.id, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	createProduit(data: any) {
		return this.http.post<any>(this.apiUrl + 'produit', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateProduit(data): any {
		return this.http.put<any>(this.apiUrl + 'produit/' + data.id, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public deleteCreneau(id: number): any {
		return this.http.delete<any>(this.apiUrl + 'planning/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getPlanningTemplate(restaurantId: number): any {
		return this.http.get<any>(this.apiUrl + 'planning_template?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	createCreneauTemplate(data: any) {
		return this.http.post<any>(this.apiUrl + 'planning_template', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateCreneauTemplate(data): any {
		return this.http.put<any>(this.apiUrl + 'planning_template/' + data.id, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public deleteCreneauTemplate(id: number): any {
		return this.http.delete<any>(this.apiUrl + 'planning_template/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getMonthEmployees(restaurantId, month): any {
		return this.http.get<any>(this.apiUrl + 'presential?restaurant_id=' + restaurantId + '&month=' + month,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getDateEmployees(restaurantId, date): any {
		return this.http.get<any>(this.apiUrl + 'presential?restaurant_id=' + restaurantId + '&date=' + date,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getMonthEmployeesTips(restaurantId, month): any {
		return this.http.get<any>(this.apiUrl + 'presential/tips?restaurant_id=' + restaurantId + '&month=' + month,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getEmployeesRestaurantHistory(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'presential/restaurant-history?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getEmployeesRestaurantTypeHistory(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'presential/restaurant-type-history?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	createPresential(data: any) {
		return this.http.post<any>(this.apiUrl + 'presential', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updatePresential(data): any {
		return this.http.put<any>(this.apiUrl + 'presential/' + data.id, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public deletePresential(id: number): any {
		return this.http.delete<any>(this.apiUrl + 'presential/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getInvitation(token: any): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'invitation?token=' + token,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createUser(data) {
		return this.http.post<any>(this.apiUrl + 'user', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getPermissionsList(): any {
		return this.http.get<any>(this.apiUrl + 'permissions',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createInvitation(restaurantId, data) {
		return this.http.post<any>(this.apiUrl + 'invitation?restaurant_id=' + restaurantId, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public editUserRestaurant(restaurantId, data): any {
		return this.http.put<any>(this.apiUrl + 'user_restaurant/' + data.id + '?restaurant_id=' + restaurantId, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addUserRestaurant(data): any {
		return this.http.post<any>(this.apiUrl + 'user_restaurant/' + data.user_id, data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public deleteUserRestaurant(restaurantId, id): any {
		return this.http.delete<any>(this.apiUrl + 'user_restaurant/' + id + '?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public signContract(id, data) {
		return this.http.post<any>(this.apiUrl + 'contracts/' + id + '/sign', data, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public sendContract(id) {
		return this.http.get<any>(this.apiUrl + 'contracts/' + id + '/contract-send', this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public downloadRecapHours(month, restaurantId): any {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		return this.http.get<any>(this.apiUrl + 'presential/export?month=' + month + '&restaurant_id=' + restaurantId, options)
			.pipe(catchError(this.handleError));
	}

	public downloadSignedContract(id): any {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		return this.http.get<any>(this.apiUrl + 'contracts/' + id + '/download-signed', options)
			.pipe(catchError(this.handleError));
	}

	public downloadContract(id): any {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		return this.http.get<any>(this.apiUrl + 'contracts/' + id + '/download', options)
			.pipe(catchError(this.handleError));
	}

	public downloadGeneratedContract(id): any {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		//		return this.http.get<any>('https://api.groin.le17-45.fr/generate-contract.php?id=' + id + '', options)
		return this.http.get<any>(this.apiUrl + 'contracts/' + id + '/contract-generate', options)
			.pipe(catchError(this.handleError));
	}

	public getContract(id): any {
		return this.http.get<any>(this.apiUrl + 'contracts/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getContractPresential(id): any {
		return this.http.get<any>(this.apiUrl + 'contracts/' + id + '/presential',
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getDocuments(restaurantId) {
		return this.http.get<any>(this.apiUrl + 'documents?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public getDocument(restaurantId, file) {
		const options = this.getHttpOptions();
		options['responseType'] = 'blob' as 'json';
		return this.http.get<any>(this.apiUrl + 'documents?restaurant_id=' + restaurantId + '&file=' + file,
			options)
			.pipe(catchError(this.handleError));
	}

	public deleteDocument(restaurantId, file) {
		return this.http.delete<any>(this.apiUrl + 'documents?restaurant_id=' + restaurantId + '&file=' + file,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public createDocumentCategory(restaurantId, categoryName) {
		return this.http.post<any>(this.apiUrl + 'documents?restaurant_id=' + restaurantId,
			{'name': categoryName},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public renameDocumentCategory(restaurantId, oldName, categoryName) {
		return this.http.post<any>(this.apiUrl + 'documents?restaurant_id=' + restaurantId,
			{'old_name': oldName, 'name': categoryName},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public uploadDocument(data, restaurantId) {
		return this.http.post<any>(this.apiUrl + 'documents?restaurant_id=' + restaurantId,
			data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	uploadContract(id: any, restaurantId, data) {
		return this.http.post<any>(this.apiUrl + 'contracts/' + id + '/upload?restaurant_id=' + restaurantId,
			data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getRestaurant(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'restaurants?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getOpeningHours(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'restaurant_opening_hours?restaurant_id=' + restaurantId,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public deleteOpeningHours(id): any {
		return this.http.delete<any>(this.apiUrl + 'restaurant_opening_hours/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getPendingReservations(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'reservation/new?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getPendingDepositReservations(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'reservation/deposit?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getTransmissions(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'transmission?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addTransmission(item: any): any {
		return this.http.post<any>(this.apiUrl + 'transmission', item,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getDateReservations(restaurantId, date): any {
		return this.http.get<any>(this.apiUrl + 'reservation/date/' + date + '?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateUser(item: any): any {
		return this.http.put<any>(this.apiUrl + 'user/' + item.id, item,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateOpeningHours(item: any): any {
		return this.http.put<any>(this.apiUrl + 'restaurant_opening_hours/' + item.id, item,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public updateFournisseurRestaurant(item: any): any {
		return this.http.put<any>(this.apiUrl + 'fournisseur_restaurant/' + item.id, item,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public createOpeningHours(item: any): any {
		return this.http.post<any>(this.apiUrl + 'restaurant_opening_hours', item,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public createCommande(restaurantId: number, fournisseurId: number, order): any {
		return this.http.post<any>(this.apiUrl + 'commande/' + fournisseurId + '?restaurant_id=' + restaurantId, order,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public createStock(data: any): any {
		return this.http.post<any>(this.apiUrl + 'stock', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public getFullRestaurant(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'full_restaurant?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getFullRestaurantToday(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'full_restaurant?today&restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getBlockedResaRestaurant(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'reservation_auto_blocked?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addBlockedResaRestaurant(data: any): any {
		return this.http.post<any>(this.apiUrl + 'reservation_auto_blocked', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public deleteBlockedResaRestaurant(id: any): any {
		return this.http.delete<any>(this.apiUrl + 'reservation_auto_blocked/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public getAvisToAnswer(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'avis/to_answer?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getAvisLatest(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'avis/latest?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getAvisStats(restaurantId): any {
		return this.http.get<any>(this.apiUrl + 'avis/stats?restaurant_id=' + restaurantId, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addFullRestaurant(data: any): any {
		return this.http.post<any>(this.apiUrl + 'full_restaurant', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public deleteFullRestaurant(id: any): any {
		return this.http.delete<any>(this.apiUrl + 'full_restaurant/' + id,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public createComptageCaisse(data: any): any {
		return this.http.post<any>(this.apiUrl + 'caisse/comptage', data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));

	}

	public getPlanningComment(restaurantId: number, week: string): any {
		return this.http.get<any>(this.apiUrl + 'planning_comment?restaurant_id=' + restaurantId + '&week=' + week,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	updatePlanningComment(id: any, data: any) {
		return this.http.put<any>(this.apiUrl + 'planning_comment/' + id,
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	createPlanningComment(data: any) {
		return this.http.post<any>(this.apiUrl + 'planning_comment',
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getPlanningTemplateComment(restaurantId: number): any {
		return this.http.get<any>(this.apiUrl + 'planning_template_comment?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	updatePlanningTemplateComment(id: any, data: any) {
		return this.http.put<any>(this.apiUrl + 'planning_template_comment/' + id,
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	createPlanningTemplateComment(data: any) {
		return this.http.post<any>(this.apiUrl + 'planning_template_comment',
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public cancelPlanning(id) {
		return this.http.post<any>(this.apiUrl + 'planning/' + id + '/cancel',
			{},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public requestPlanning(id) {
		return this.http.post<any>(this.apiUrl + 'planning/' + id + '/request',
			{},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public connectAs(id) {
		return this.http.post<any>(this.apiUrl + 'auth/connect-as',
			{'id': id},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getClosuresStats(restaurant_id) {
		return this.http.get<any>(this.apiUrl + 'closure?restaurant_id=' + restaurant_id,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCommunicationSent(restaurant_id) {
		return this.http.get<any>(this.apiUrl + 'communication_sent?restaurant_id=' + restaurant_id,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getTicketsCategories() {
		return this.http.get<any>(this.apiUrl + 'ticket/categories',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getRedevanceConfig() {
		return this.http.get<any>(this.apiUrl + 'redevance_config?restaurant_id=',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getTicketsStatuses() {
		return this.http.get<any>(this.apiUrl + 'ticket/status',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getTickets() {
		return this.http.get<any>(this.apiUrl + 'ticket',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createTicket(ticket) {
		return this.http.post<any>(this.apiUrl + 'ticket',
			ticket,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getTicketDetail(ticketId: number) {
		return this.http.get<any>(this.apiUrl + 'ticket/' + ticketId + '/details',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCommandeByToken(token) {
		return this.http.get<any>(this.apiUrl + 'commande?token=' + token,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public addMessageToTicket(id, message?, status?, type?, file_content?): any {
		return this.http.post<any>(this.apiUrl + 'ticket/' + id + '/message' + (status ? '?status=' + status : ''),
			{'message': message, 'file_content': file_content, 'type': type},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public validateCommande(commande: any) {
		return this.http.post<any>(this.apiUrl + 'commande',
			{'token': commande.token, 'validate': 1},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addFournisseurRestaurant(restaurantId: number, data) {

		return this.http.post<any>(this.apiUrl + 'fournisseur_restaurant',
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	addProfilePic(image): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'user_pic',
			image, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public addUserToTicket(id: number, employee_id: any): any {
		return this.http.post<any>(this.apiUrl + 'ticket/' + id + '/add-user',
			{'user_id': employee_id},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addNotificationToken(currentToken: string): any {
		return this.http.post<any>(this.apiUrl + 'user_notification_token',
			{'token': currentToken},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public loadInvoice(restaurantId: any, period: string): any {
		return this.http.get<any>(this.apiUrl + 'redevance?restaurant_id=' + restaurantId + '&period=' + period,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public loadDevisList(restaurantId: any, type: string): any {
		return this.http.get<any>(this.apiUrl + 'devis?restaurant_id=' + restaurantId + '&type=' + type,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public loadDevis(id: number): any {
		return this.http.get<any>(this.apiUrl + 'devis/' + id,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public generateInvoice(data): any {
		return this.http.post<any>(this.apiUrl + 'redevance', data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public loadDevisLabel(): any {

		return this.http.get<any>(this.apiUrl + 'devis/label',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public loadDevisVersions(devisId: number): any {

		return this.http.get<any>(this.apiUrl + 'devis/' + devisId + '/versions',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public loadDevisItems(devisId: number, versionId: number): any {
		return this.http.get<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/items',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public duplicateVersion(devisId, versionId): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/duplicate', {}, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateDevis(devisId, data): any {
		return this.http.put<any>(this.apiUrl + 'devis/' + devisId, data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createDevis(data): any {
		return this.http.post<any>(this.apiUrl + 'devis', data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateDevisItem(devisId, versionId, itemId, data): any {
		return this.http.put<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/' + itemId, data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createDevisItem(devisId, versionId, data): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/item', data, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public validateVersion(devisId: number, versionId: number): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/validate', {}, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public cancelVersion(devisId: number, versionId: number): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/cancel', {}, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public generateQuote(devisId: number, versionId: number): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/generate', {}, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public generateVersion(devisId: number): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/generate', {}, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public deleteDevisItem(devisId: number, versionId: number, itemId: number): any {
		return this.http.delete<any>(this.apiUrl + 'devis/' + devisId + '/' + versionId + '/' + itemId, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateTicket(ticketId: number, ticket): any {

		return this.http.put<any>(this.apiUrl + 'ticket/' + ticketId,
			ticket,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public answerAvis(avis: any): any {
		return this.http.put<any>(this.apiUrl + 'avis/' + avis.id,
			avis,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public getCarteProduitIcons(): any {
		return this.http.get<any>(this.apiUrl + 'carte_produit/icon',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCarteProduitBanners(): any {
		return this.http.get<any>(this.apiUrl + 'carte_produit/banner',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCarteCategorie(): any {
		return this.http.get<any>(this.apiUrl + 'carte_categorie',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCarteProduit(): any {
		return this.http.get<any>(this.apiUrl + 'carte_produit',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createCarteProduit(item: any): any {
		return this.http.post<any>(this.apiUrl + 'carte_produit', item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateCarteProduit(item: any): any {
		return this.http.put<any>(this.apiUrl + 'carte_produit/' + item.id, item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCarteProduitTranslation(lang: string): any {
		return this.http.get<any>(this.apiUrl + 'carte_produit_translation/' + lang,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createCarteProduitTranslation(item: any): any {
		return this.http.post<any>(this.apiUrl + 'carte_produit_translation', item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateCarteProduitTranslation(item: any): any {
		return this.http.put<any>(this.apiUrl + 'carte_produit_translation/' + item.id, item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCarteProduitRestaurant(lang: string): any {
		return this.http.get<any>(this.apiUrl + 'carte_produit_restaurant/' + lang,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public createCarteProduitRestaurant(item: any): any {
		return this.http.post<any>(this.apiUrl + 'carte_produit_restaurant', item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateCarteProduitRestaurant(item: any): any {
		return this.http.put<any>(this.apiUrl + 'carte_produit_restaurant/' + item.id, item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updateUserLang(lang) {
		return this.http.post<any>(this.apiUrl + 'user/lang', {'lang': lang},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public me(): any {
		return this.http.get<any>(this.apiUrl + 'user/me', this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	private getHttpOptions() {
		if (this.token) {
			this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.token);
		} else {
			this.httpOptions.headers.delete('Authorization');
		}
		return this.httpOptions;
	}

	public getCaMonth(id): any {
		return this.http.get<any>(this.apiUrl + 'stats/ca_month?restaurant_id=' + id,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getSupersetToken(id): any {
		return this.http.get<any>(this.apiUrl + 'stats/superset?restaurant_id=' + id,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getCaYear(id): any {
		return this.http.get<any>(this.apiUrl + 'stats/ca_year?restaurant_id=' + id,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getResaComments(id: number) {
		return this.http.get<any>(this.apiUrl + 'reservation/' + id + '/comments',
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public addResaComment(id: number, comment: any): any {
		return this.http.post<any>(this.apiUrl + 'reservation/' + id + '/comment', {'content': comment}, this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public loadDevisPaiements(devisId: number): any {
		return this.http.get<any>(this.apiUrl + 'devis/' + devisId + '/paiements',
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public updatePaiements(devisId: number): any {
		return this.http.post<any>(this.apiUrl + 'devis/' + devisId + '/update-payments', {}, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public deleteUserFromTicket(id: number, employee_id: any): any {
		return this.http.post<any>(this.apiUrl + 'ticket/' + id + '/delete-user',
			{'user_id': employee_id},
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	public saveCommandeLocal(restaurant_id, item: any): any {
		return this.http.post<any>(this.apiUrl + 'commande_local?restaurant_id=' + restaurant_id, item, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public deleteNotification(id): any {
		return this.http.delete<any>(this.apiUrl + 'notification?id=' + id, this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	public getNotifications(): any {
		return this.http.get<any>(this.apiUrl + 'notification', this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	getClosing(restaurantId: number, period: string): any {
		return this.http.get<any>(this.apiUrl + 'closing/' + period + '?restaurant_id=' + restaurantId,
			this.getHttpOptions()).pipe(catchError(this.handleError));
	}

	updateClosing(id: any, data: any) {
		return this.http.put<any>(this.apiUrl + 'closing/' + id,
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}

	createClosing(data: any) {
		return this.http.post<any>(this.apiUrl + 'closing',
			data,
			this.getHttpOptions())
			.pipe(catchError(this.handleError));
	}
}
