<div class="header-container">
	<div class="logo-container">
		<a (click)="toggleSidebar()" class="sidebar-toggle" href="#">
			<nb-icon icon="menu-2-outline"></nb-icon>
		</a>
		<a (click)="navigateHome()" class="logo" href="#">
			<img alt="GROIN" class="d-none d-md-block" src="/assets/logo-groin.png" style="max-width: 100%;">
			<img alt="GROIN" class="d-block d-md-none" src="/assets/logo-groin-sm.png" style="max-width: 100%;">
		</a>
	</div>
</div>

<div class="header-container">

	<nb-select (selectedChange)="changeRestaurant($event)" *ngIf="enableRestaurantPicker()" [(selected)]="currentRestaurant" placeholder="{{ 'Choisir un restaurant' | translate}}" status="primary">
		<nb-option *ngFor="let restaurant of restaurants" [value]="restaurant">
			<ng-container *ngIf="restaurant.type === 'succursale'">👑 -</ng-container>
			{{ restaurant.nom_court }}
		</nb-option>
	</nb-select>
	<div style="width: 30px;text-align: center">
		<div *ngIf="'dark' !== currentTheme" (click)="changeTheme('dark')">
			<i class="fa fa-lightbulb"></i>
		</div>
		<div *ngIf="'default' !== currentTheme" (click)="changeTheme('default')">
			<i class="fa fa-lightbulb-on"></i>
		</div>

	</div>
	<nb-actions size="medium">
		<nb-action *nbIsGranted="['view', 'user']" class="user-action">
			<nb-user [name]="user?.firstname"
			         [nbContextMenu]="userMenu"
			         [onlyPicture]="userPictureOnly"
			         [picture]="'https://api.groin.le17-45.fr/user_pic?id='+user.id">
			</nb-user>
		</nb-action>
	</nb-actions>
	<div class="notification-container">
		<div (click)="this.notificationCenterOpen = !this.notificationCenterOpen;">
			<i class="fas fa-bell fa-2x" [class.blinknotif]="notifications.length > 0"></i>
		</div>
		<div class="notification-dropdown" *ngIf="notificationCenterOpen">
			<div *ngIf="notifications.length === 0" class="empty-notifications">
				<p translate>Aucune notification</p>
			</div>
			<div style="max-height: 80vh;overflow-y: auto">
				<div *ngFor="let notification of notifications" class="notification-item">
					<div style="display: inline-block; color: #F4C3CB;cursor: pointer; vertical-align: middle;" (click)="deleteNotification(notification)">
						<i class="fas fa-trash"></i>
					</div>
					<div style="display: inline-block">
						<p [innerHTML]="notification.content"></p>
						<span class="time">{{ notification.created_at | date: 'short' }}</span>
					</div>
				</div>
			</div>
			<div class="notification-footer">
				<button class="btn btn-sm btn-outline-danger btn-clear" style="border-color: #F4C3CB;color: #F4C3CB;" (click)="clearNotifications()" translate>Effacer tout</button>
			</div>
		</div>
	</div>
</div>
<ng-template #crop let-data let-ref="dialogRef">
	<nb-card>
		<nb-card-header>Photo de profil</nb-card-header>
		<nb-card-body>
			<image-cropper
				(cropperReady)="cropperReady()"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded($event)"
				(loadImageFailed)="loadImageFailed()"
				[aspectRatio]="1"
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
				[onlyScaleDown]="true"
				format="jpeg"
				imageQuality="80"
				resizeToWidth="512"
				style="max-height:50vh"
			></image-cropper>
		</nb-card-body>
		<nb-card-footer>
			<button (click)="ref.close()" nbButton status="success">Annuler</button>
			<button (click)="uploadProfilePic(ref)" nbButton status="success">Mettre en ligne</button>
		</nb-card-footer>
	</nb-card>
</ng-template>
<input #fileInput (change)="onFileSelected($event)" style="display:none;" type="file"/>
