import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';
import {
	NbActionsModule,
	NbAlertModule,
	NbButtonModule,
	NbCardModule,
	NbContextMenuModule,
	NbIconModule,
	NbLayoutModule,
	NbMenuModule,
	NbSearchModule,
	NbSelectModule,
	NbSidebarModule,
	NbThemeModule,
	NbUserModule,
} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {LangPickerComponent} from '../shared/lang-picker/lang-picker.component';

import {FooterComponent, HeaderComponent, SearchInputComponent} from './components';
import {OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent} from './layouts';
import {PipesModule} from './pipes/pipes.module';
import {DARK_THEME} from './styles/theme.dark';
import {DEFAULT_THEME} from './styles/theme.default';

const NB_MODULES = [
	NbLayoutModule,
	NbMenuModule,
	NbUserModule,
	NbActionsModule,
	NbSearchModule,
	NbSidebarModule,
	NbContextMenuModule,
	NbSecurityModule,
	NbButtonModule,
	NbSelectModule,
	NbIconModule,
	NbEvaIconsModule,
];
const COMPONENTS = [
	HeaderComponent,
	FooterComponent,
	SearchInputComponent,
	OneColumnLayoutComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,
];

@NgModule({
	imports: [CommonModule, PipesModule, ...NB_MODULES, NbCardModule, ImageCropperModule, NbAlertModule, TranslateModule, LangPickerComponent, FontAwesomeModule],
	exports: [CommonModule, PipesModule, ...COMPONENTS],
	declarations: [...COMPONENTS],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders<ThemeModule> {
		let darkModeOn = null;
		if (localStorage.getItem('theme')) {
			darkModeOn = localStorage.getItem('theme') === 'dark';
		}
		if (darkModeOn === null) {
			darkModeOn =
				window.matchMedia &&
				window.matchMedia('(prefers-color-scheme: dark)').matches;
		}

		return {
			ngModule: ThemeModule,
			providers: [
				PipesModule,
				...NbThemeModule.forRoot(
					{
						name: darkModeOn ? 'dark' : 'default',
					},
					[DEFAULT_THEME, DARK_THEME],
				).providers,
			],
		};
	}
}
